// COMPONENTS
import React, { useContext } from 'react'
import marked from 'marked'
import { ThemeContext } from 'layout/ThemeProvider'

// Markdown body component
const MarkdownBody = ({ markdown, inverted, addClass }) => {
  let { themeMode, invertedTheme } = useContext(ThemeContext)
  if (inverted) [themeMode, invertedTheme] = [invertedTheme, themeMode]
  return (
    <div className={`md-body text-left text-${invertedTheme} ${addClass}`}>
      {markdown && (
        <div dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
      )}
    </div>
  )
}

export default MarkdownBody

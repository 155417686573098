import React, { useContext } from 'react'
import { ThemeContext } from 'layout/ThemeProvider'
// ENDCAP TYPES
// import Arrow from './arrow.svg'
// import Accepted from './accepted.svg'
// import Rejected from './rejected.svg'
// import Unicorn from './unicorn.svg'
import Diagonal from 'designImages/Diagonal.svg'
import Rectangular from 'designImages/Rectangular.svg'

// https://www.gatsbyjs.com/plugins/gatsby-plugin-react-svg/
// https://tech.bakkenbaeck.com/post/inline-svg-react
// Section endcap component
const Endcap = ({ type, height = '100px', width = '100%', inverted }) => {
  let { themeMode, invertedTheme } = useContext(ThemeContext)
  if (inverted) [themeMode, invertedTheme] = [invertedTheme, themeMode]

  // Select endcap type
  const setType = type => {
    switch (type) {
      case 'none':
        return null
      case 'diagonal':
        return Diagonal
      case 'rectangular':
        return Rectangular
      // case 'accepted': return Accepted
      // case 'rejected': return Rejected
      // case 'unicorn': return Unicorn
      default:
        return Rectangular
    }
  }
  const SVG = setType(type)
  return(
  <>{SVG && <SVG className={themeMode} height={height} width={width} /> }</>
  )
}

export default Endcap
